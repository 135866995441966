import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, Link } from "react-router-dom";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { isExpired } from "react-jwt";
import GreenAlert from "../assets/images/green_alert.svg";
// import { Button } from "react-bootstrap";
import { Navbar, Container, Dropdown, Nav } from "react-bootstrap";
import Logo from "../assets/images/logo.svg";
import LogoBlack from "../assets/images/black_xapads_logo.svg";
import UserIcon from "../assets/images/user_icon.svg";
import BlackUserIcon from "../assets/images/user_black_icons.svg";
import axios from "axios";
import configData from "../config/config.json";
import MobileIconView from '../assets/images/mobile_icon_view.svg'


function Header() {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [loggedin, setLoggedin] = useState(false);
  const [step, setStep] = useState(0);
  const [status, setStatus] = useState("");

  const [activeButton, setActiveButton] = useState("");

  const location = useLocation();

  React.useEffect(() => {
    let status = Cookies.get("token_v1") ? true : false;
    setLoggedin(status);

    if (status) {
      let token = Cookies.get("token_v1");
      if (token) {
        setEmail(jwtDecode(token)?.email);
        // setUsername(jwtDecode(token)?.name);
        setStep(jwtDecode(token)?.step);
        setStatus(jwtDecode(token)?.status);
      }

      axios
        .get(configData.SERVER_URL + `publisherName`, {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": token,
          },
        })
        .then((response) => {
          if (response.data.status === 200) {
            setUsername(response.data.data);
          } else {
          }
        })
        .catch((error) => { });
    }

    setActiveButton(location.pathname);
  }, [location.pathname]);

  React.useEffect(() => {
    const tokenExpired = async () => {
      let status = Cookies.get("token_v1") ? true : false;
      if (status) {
        let token = Cookies.get("token_v1");
        const isMyTokenExpired = isExpired(token);
        if (isMyTokenExpired) {
          toast.error("Token Expired! Signing Out", {
            autoClose: 1500,
            position: "top-center",
          });
          setTimeout(() => {
            Cookies.remove("IP");
            Cookies.remove("token_v1");
            window.location.replace("/login");
          }, 1500);
        }
      }
    };

    const timeoutId = setTimeout(() => {
      tokenExpired();
      const intervalId = setInterval(tokenExpired, 15000); // Call every 15 sec
      return () => clearInterval(intervalId);
    }, 10000);

    return () => clearTimeout(timeoutId);
  }, []);

  React.useEffect(() => {
    const accountCheck = async () => {
      if (email !== "") {
        let status = Cookies.get("token_v1") ? true : false;
        if (status) {
          let token = Cookies.get("token_v1");
          let emailToken = jwtDecode(token).email;
          if (emailToken !== email) {
            window.location.reload();
          }
        } else {
          window.location.reload();
        }
      } else {
        let status = Cookies.get("token_v1") ? true : false;
        if (status) {
          window.location.reload();
        }
      }
    };

    const timeoutId = setTimeout(() => {
      const intervalId = setInterval(accountCheck, 2000); // CHECK IN  every 2 sec
      return () => clearInterval(intervalId);
    }, 2000);

    return () => clearTimeout(timeoutId);
  });

  const notifysuccess = () =>
    toast.success(
      <>
        <img src={GreenAlert} alt="" className="img-fluid me-2" />
        Logged Out Successfully
      </>,
      { autoClose: 1500, position: "top-center" }
    );

  const logout = () => {
    notifysuccess("User logged out successfully.");

    setTimeout(() => {
      Cookies.remove("token_v1");
      window.location.replace("/login");
    }, 1000);
  };

  if (!loggedin) {
    return (
      <>
        <ToastContainer />
      </>
    );
  } else {
    return (
      <>
        <ToastContainer />
        <div className="show_mobile_view">
          <img src={LogoBlack} className="img-fluid one_logo" alt="" />
          <img src={MobileIconView} className="img-fluid two_logo" alt="" />
          <h3>The page you are viewing is not compatible for mobile.</h3>
          <p>To access the page, please use Desktop/ Laptop.</p>
          <button onClick={logout} className="submit_btn_design ripple btn w-75">Log Out </button>
        </div>

        {step === 1 && status === "active" ? (
          <div className="header_bg_cls second_header d-none d-lg-block">
            <Navbar expand="lg" className="bg-body-tertiary">
              <Container fluid className="p-0">
                <Navbar.Brand href="#">
                  <img src={LogoBlack} className="img-fluid" alt="" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="me-auto">
                    <Link
                      className={
                        activeButton === "/dashboard" ? "active_li_a" : ""
                      }
                      to="/dashboard"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          d="M6.08151 13.9207V11.8507C6.0815 11.3241 6.51075 10.8963 7.04257 10.8928H8.99068C9.52504 10.8928 9.95823 11.3216 9.95823 11.8507V11.8507V13.9271C9.95811 14.3742 10.3185 14.7395 10.7699 14.75H12.0687C13.3633 14.75 14.4129 13.711 14.4129 12.4292V12.4292V6.54054C14.406 6.03631 14.1668 5.56281 13.7635 5.2548L9.32185 1.71258C8.54372 1.09581 7.43758 1.09581 6.65945 1.71258L2.23726 5.26123C1.83242 5.56799 1.59288 6.04227 1.58789 6.54697V12.4292C1.58789 13.711 2.63743 14.75 3.93211 14.75H5.23084C5.69348 14.75 6.06852 14.3787 6.06852 13.9207V13.9207"
                          stroke="#616161"
                          stroke-width="1.3"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      Dashboard
                    </Link>
                    <Link
                      className={
                        activeButton === "/listCampaign" ? "active_li_a" : ""
                      }
                      to="#"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          d="M10.8744 5.13097L6.58178 9.46939L1.69958 6.41575C1.00006 5.97811 1.14557 4.91558 1.93679 4.68419L13.5284 1.28958C14.2529 1.07722 14.9244 1.75462 14.7092 2.4815L11.2798 14.0651C11.0449 14.8574 9.98841 14.999 9.5549 14.2964L6.5795 9.47015"
                          stroke="#616161"
                          stroke-width="1.3"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      Campaigns
                    </Link>
                    <Link
                      className={
                        activeButton === "/reporting" ? "active_li_a" : ""
                      }
                      to="#"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="17"
                        height="16"
                        viewBox="0 0 17 16"
                        fill="none"
                      >
                        <path
                          d="M4.43359 10.0861L6.67844 7.16853L9.23909 9.17997L11.4359 6.34473"
                          stroke="#616161"
                          stroke-width="1.3"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <ellipse
                          cx="13.9963"
                          cy="2.15014"
                          rx="1.44165"
                          ry="1.44165"
                          stroke="#616161"
                          stroke-width="1.3"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M10.1938 1.34009H4.74302C2.48444 1.34009 1.08398 2.93963 1.08398 5.19821V11.26C1.08398 13.5186 2.45698 15.1113 4.74302 15.1113H11.1961C13.4547 15.1113 14.8552 13.5186 14.8552 11.26V5.98082"
                          stroke="#616161"
                          stroke-width="1.3"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      Reports
                    </Link>
                    <Link
                      className={
                        activeButton === "/account" ? "active_li_a" : ""
                      }
                      to="/account"
                    >
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.99609 12.5076V10.6049"
                          stroke="#616161"
                          stroke-width="1.3"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M13.6425 3.99768C14.91 3.99768 15.93 5.02518 15.93 6.29268V8.87268C14.085 9.95268 11.6475 10.6052 8.9925 10.6052C6.3375 10.6052 3.9075 9.95268 2.0625 8.87268V6.28518C2.0625 5.01768 3.09 3.99768 4.3575 3.99768H13.6425Z"
                          stroke="#616161"
                          stroke-width="1.3"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M11.6211 3.99438V3.71988C11.6211 2.80488 10.8786 2.06238 9.96359 2.06238H8.02859C7.11359 2.06238 6.37109 2.80488 6.37109 3.71988V3.99438"
                          stroke="#616161"
                          stroke-width="1.3"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M2.08008 11.6123L2.22183 13.4941C2.31783 14.7623 3.37458 15.7426 4.64583 15.7426H13.3451C14.6163 15.7426 15.6731 14.7623 15.7691 13.4941L15.9108 11.6123"
                          stroke="#616161"
                          stroke-width="1.3"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      Account Settings
                    </Link>
                  </Nav>
                  <Dropdown className="user_profile_dropdown">
                    <Dropdown.Toggle variant="" id="dropdown-basic">
                      <img
                        src={BlackUserIcon}
                        className="img-fluid me_8"
                        alt=""
                      />{" "}
                      {username?.split(" ")[0]}
                    </Dropdown.Toggle>

                    <Dropdown.Menu align="end">
                      <Dropdown.Item className="user_name_cls">
                        {email}
                      </Dropdown.Item>
                      <hr />
                      <Dropdown.Item className="pb-0" href="/profile">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="14"
                          className="me_6"
                          viewBox="0 0 12 14"
                          fill="none"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M5.9893 9.23071C3.41089 9.23071 1.20898 9.62055 1.20898 11.1818C1.20898 12.7431 3.39692 13.1469 5.9893 13.1469C8.56771 13.1469 10.769 12.7564 10.769 11.1958C10.769 9.63516 8.58168 9.23071 5.9893 9.23071Z"
                            stroke="#4A3AFF"
                            stroke-width="1.3"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M5.98993 7.00384C7.68199 7.00384 9.05342 5.63178 9.05342 3.93971C9.05342 2.24765 7.68199 0.876221 5.98993 0.876221C4.29786 0.876221 2.9258 2.24765 2.9258 3.93971C2.92008 5.62606 4.28262 6.99812 5.96834 7.00384H5.98993Z"
                            stroke="#4A3AFF"
                            stroke-width="1.3"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        My Profile
                      </Dropdown.Item>
                      <Dropdown.Item onClick={logout}>
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          className="me_6"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.0107 4.92632V4.30432C10.0107 2.94766 8.91069 1.84766 7.55402 1.84766H4.30402C2.94802 1.84766 1.84802 2.94766 1.84802 4.30432V11.7243C1.84802 13.081 2.94802 14.181 4.30402 14.181H7.56069C8.91336 14.181 10.0107 13.0843 10.0107 11.7317V11.103"
                            stroke="#4A3AFF"
                            stroke-width="1.3"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M14.5397 8.01424H6.51233"
                            stroke="#4A3AFF"
                            stroke-width="1.3"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M12.5875 6.07086L14.5395 8.0142L12.5875 9.95819"
                            stroke="#4A3AFF"
                            stroke-width="1.3"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        Log Out
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Navbar.Collapse>
              </Container>
            </Navbar>
          </div>
        ) : (
          <div className="header_bg_cls d-none d-lg-block">
            <Navbar className="">
              <Container fluid className="p-0">
                <Navbar.Brand href="#">
                  <img src={Logo} className="img-fluid" alt="" />
                </Navbar.Brand>
                <Navbar.Toggle />
                <Navbar.Collapse className="justify-content-end">
                  <Navbar.Text>
                    <Dropdown className="user_profile_dropdown">
                      <Dropdown.Toggle variant="" id="dropdown-basic">
                        <img src={UserIcon} className="img-fluid me_8" alt="" />{" "}
                        {/* {username?.split(" ")[0]} */}
                        {username?.split(" ")[0]}
                      </Dropdown.Toggle>

                      <Dropdown.Menu align="end">
                        <Dropdown.Item className="user_name_cls">
                          {email}
                        </Dropdown.Item>
                        <hr />
                        <Dropdown.Item onClick={logout}>
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            className="me_6"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M10.0107 4.92632V4.30432C10.0107 2.94766 8.91069 1.84766 7.55402 1.84766H4.30402C2.94802 1.84766 1.84802 2.94766 1.84802 4.30432V11.7243C1.84802 13.081 2.94802 14.181 4.30402 14.181H7.56069C8.91336 14.181 10.0107 13.0843 10.0107 11.7317V11.103"
                              stroke="#4A3AFF"
                              stroke-width="1.3"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M14.5397 8.01424H6.51233"
                              stroke="#4A3AFF"
                              stroke-width="1.3"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M12.5875 6.07086L14.5395 8.0142L12.5875 9.95819"
                              stroke="#4A3AFF"
                              stroke-width="1.3"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                          Log Out
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Navbar.Text>
                </Navbar.Collapse>
              </Container>
            </Navbar>
          </div>
        )}

        {/* starting the new navbar for inner panels */}

      </>
    );
  }
}

export default Header;
