import Account from "../components/Account";

function AccountPage() {
  return (
    <>
      <Account />
    </>
  );
}

export default AccountPage;