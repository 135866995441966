import Login from "../components/Login";
import { useLocation } from "react-router-dom";

function LoginPage() {
  const search = useLocation().search;
  const token = new URLSearchParams(search).get("token");

  return (
    <>
      <Login  token={token}/>
    </>
  );
}

export default LoginPage;