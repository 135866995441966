import Onboarding from "../components/Onboarding";

function OnboardingPage() {
  return (
    <>
      <Onboarding />
    </>
  );
}

export default OnboardingPage;