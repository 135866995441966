import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Form, Button } from 'react-bootstrap';
import { ToastContainer, toast } from "react-toastify";
import { LoadingOutlined } from '@ant-design/icons'
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import configData from "../config/config.json";
import { TokenVerificationLoggedOut } from "../utils/tokenVerify";
import SignUpImg from '../assets/images/sign_up_vector.svg'
import Logo from '../assets/images/xapads_logo_outer.svg'
import RedAlert from '../assets/images/red_alert.svg'
import GreenAlert from '../assets/images/green_alert.svg'


const ForgotPassword = () => {
  const buttonRef2 = useRef(null);

  React.useEffect(() => {
    TokenVerificationLoggedOut();
  });

  useEffect(() => {
    const handleEnterKeyPress2 = (event) => {
      if (event.key === 'Enter') {
        buttonRef2.current.click();

      }
    };

    // Add keydown event listener to the document
    document.addEventListener('keydown', handleEnterKeyPress2);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener('keydown', handleEnterKeyPress2);
    };
  }, []);

  const notifysuccess = (msg) => toast.success(<>
    <img src={GreenAlert} alt="" className="img-fluid me-2" />
    {msg}
  </>, { autoClose: 2000, position: "top-center" });
  const notifyfailure = (msg) => toast.error(<>
    <img src={RedAlert} alt="" className="img-fluid me-2" />
    {msg}
  </>, { autoClose: 1500, position: "top-center" });
  const notifycatch = () =>
    toast.error(
      <>
        <img src={RedAlert} alt="" className="img-fluid me-2" />
        {"Something went wrong"}
      </>

      , { autoClose: 1500, position: "top-center" });

  const [email, setEmail] = useState(null);
  const [emailIsRequiredError, setEmailIsRequiredError] = useState(false);
  const [emailUrlError, setEmailUrlError] = useState(false);

  const [buttonValue, setButtonValue] = useState(false);

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  function onChangeEmail(event) {
    setEmailIsRequiredError(false);
    setEmail(event.target.value);

    if (validateEmail(event.target.value) === null) {
      setEmailUrlError(true);
    } else {
      setEmailUrlError(false);
    }
  }

  function onBlurEmail(event) {
    if (event.target.value.length === 0) {
      setEmailUrlError(false);
      setEmailIsRequiredError(true);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (email !== null) {
      if (emailIsRequiredError === false && emailUrlError === false) {
        let payload = {
          email: email,
        };

        setButtonValue(true);

        axios
          .post(configData.SERVER_URL + "forgotPassword", payload)
          .then((response) => {

            if (response.data.status === 200) {
              notifysuccess(response.data.message);


              setTimeout(() => {
                window.location.replace("/login")

              }, 1000)

            } else {
              notifyfailure(response.data.message);
              setButtonValue(false);
            }
          })
          .catch((error) => {
            notifycatch();

            setButtonValue(false);
          });
      }
    } else {
      if (email === null) {
        setEmailIsRequiredError(true);
      }
    }
  };

  return (
    <>
      <ToastContainer />
      <div className='sign_bg_design'>
        <div className="mobile_cls_design">
          <div className='logo_area text-center'>
            <img src={Logo} className='img-fluid' alt="" />
          </div>
          <div className='sign_up_page mt-32'>
            <div className='graph_side_area'>
              <h1>Welcome Back!</h1>
              <p>Enter your credentials to access your account.</p>
              <img src={SignUpImg} className='img-fluid img_set_bottom' alt="" />
            </div>
            <div className='input_side_area position-relative'>
              <div className='w-100 verify_account_screen'>

                <div className='diff_text_content'>
                  <h1>Forgot Password</h1>
                  <p>Enter you email and we'll send you a link to reset your password. </p>
                </div>
                {/* <Form> */}
                  <Form.Group className="mt-52" controlId="">
                    <div className='d-flex align-items-start justify-content-between'>
                      <Form.Label>Email Address *</Form.Label>

                      {emailIsRequiredError && (
                        <span className="alert_msg">
                          This field is required
                        </span>
                      )}
                      {emailUrlError && (
                        <span className="alert_msg">
                          Invalid Email Address
                        </span>
                      )}
                    </div>
                    <div className='input_addon_cls'>
                      <Form.Control type="email" className="" placeholder="example@gmail.com"
                        value={email}
                        onBlur={onBlurEmail}
                        onChange={onChangeEmail}
                      />
                      <span>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M10 17C9.04333 17 8.13917 16.8161 7.2875 16.4484C6.43583 16.0807 5.69197 15.579 5.0559 14.9434C4.4203 14.3078 3.91863 13.5642 3.5509 12.7125C3.18317 11.8608 2.99953 10.9567 3 10C3 9.03167 3.18387 8.12447 3.5516 7.2784C3.91933 6.43233 4.421 5.69173 5.0566 5.0566C5.6922 4.42053 6.4356 3.91887 7.2868 3.5516C8.138 3.18433 9.0424 3.00047 10 3C10.9683 3 11.8755 3.18387 12.7216 3.5516C13.5677 3.91933 14.3083 4.421 14.9434 5.0566C15.5795 5.6922 16.0811 6.43303 16.4484 7.2791C16.8157 8.12517 16.9995 9.03213 17 10V11.015C17 11.7033 16.7639 12.2897 16.2916 12.7741C15.8193 13.2585 15.2388 13.5005 14.55 13.5C14.13 13.5 13.7392 13.4067 13.3775 13.22C13.0158 13.0333 12.7183 12.7883 12.485 12.485C12.17 12.8 11.7995 13.048 11.3734 13.2291C10.9473 13.4102 10.4895 13.5005 10 13.5C9.03167 13.5 8.20613 13.1586 7.5234 12.4759C6.84067 11.7932 6.49953 10.9679 6.5 10C6.5 9.03167 6.84137 8.20613 7.5241 7.5234C8.20683 6.84067 9.03213 6.49953 10 6.5C10.9683 6.5 11.7939 6.84137 12.4766 7.5241C13.1593 8.20683 13.5005 9.03213 13.5 10V11.015C13.5 11.3533 13.605 11.6186 13.815 11.8109C14.025 12.0032 14.27 12.0995 14.55 12.1C14.83 12.1 15.075 12.0036 15.285 11.8109C15.495 11.6182 15.6 11.3529 15.6 11.015V10C15.6 8.47167 15.0486 7.15613 13.9459 6.0534C12.8432 4.95067 11.5279 4.39953 10 4.4C8.47167 4.4 7.15613 4.95137 6.0534 6.0541C4.95067 7.15683 4.39953 8.47213 4.4 10C4.4 11.5283 4.95137 12.8439 6.0541 13.9466C7.15683 15.0493 8.47213 15.6005 10 15.6H12.8C12.9983 15.6 13.1647 15.6672 13.2991 15.8016C13.4335 15.936 13.5005 16.1021 13.5 16.3C13.5 16.4983 13.4328 16.6647 13.2984 16.7991C13.164 16.9335 12.9979 17.0005 12.8 17H10ZM10 12.1C10.5833 12.1 11.0792 11.8958 11.4875 11.4875C11.8958 11.0792 12.1 10.5833 12.1 10C12.1 9.41667 11.8958 8.92083 11.4875 8.5125C11.0792 8.10417 10.5833 7.9 10 7.9C9.41667 7.9 8.92083 8.10417 8.5125 8.5125C8.10417 8.92083 7.9 9.41667 7.9 10C7.9 10.5833 8.10417 11.0792 8.5125 11.4875C8.92083 11.8958 9.41667 12.1 10 12.1Z" fill="#CFD3D4" />
                        </svg>
                      </span>
                    </div>
                  </Form.Group>
                  {!buttonValue && (
                    <Button variant="" type="button" className='submit_btn_design mt-20 ripple'
                      onClick={handleSubmit}
                      ref={buttonRef2}
                    > Submit </Button>
                  )}
                  {buttonValue && (
                    <Button className='submit_btn_design mt-20 ripple' type="submit">
                      Processing <LoadingOutlined className="ms-2" />
                    </Button>
                  )}
                  <p className='mt-12 mb-0'>Don’t have an account? <Link to='/register' className='sign_up_link'>Sign Up</Link></p>
                {/* </Form> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
