import React from "react";
import Cookies from "js-cookie";
import { TokenVerificationLoggedin } from "../utils/tokenVerify";

function NoPageComponent() {

const [loggedin, setLoggedIn] = React.useState(false);

  React.useEffect(() => {
    setLoggedIn(Cookies.get("token_v1") ? true : false);
    TokenVerificationLoggedin();
  }, []);



  if (loggedin) {
    return (
      <>
      <div style={{marginLeft:"42%" ,marginTop:"15%" ,position:"absolute"}}>
      <h2>Page Not Found</h2>
              <p>
                We're sorry, the page you requested could be not found. <br />{" "}
                Please go back to Dashboard
              </p>
              <button
                class="d-inline-flex justify-content-center default_btn btn px-4"
                onClick={() => {
                  window.location.replace("/dashboard");
                }}
              >
                Go To Dashboard
              </button>
      </div>
     
      </>
    );
  } else {
    return <></>;
  }
}

export default NoPageComponent;