import React,{useState} from "react";
import { TokenVerificationLoggedin, BlockDashboard } from "../utils/tokenVerify";
import "react-toastify/dist/ReactToastify.css";
// import { LoadingContext } from "react-router-loading";
import { ToastContainer } from "react-toastify";
import DashboardImg from '../assets/images/dashboard_img.svg'
import Loader from "../assets/images/loading-54-unscreen.gif";


function Dashboard() {
  // const loadingContext = useContext(LoadingContext);

  // const notifysuccess = (msg) => toast.success(msg, { autoClose: 1000 });
  // const notifyfailure = (msg) => toast.error(msg, { autoClose: 1000 });
  // const notifycatch = () =>
  //   toast.error("Something went wrong", { autoClose: 2000 });

const[loading,setLoading]=useState(true)

  React.useEffect(() => {
    TokenVerificationLoggedin();
    BlockDashboard();

    setTimeout(()=>{setLoading(false)},1200)
    // loadingContext.done();
  }, []);


  if(loading){
    return(
    <div className="loader_img">
    <img src={Loader} className="img-fluid" alt="" />
  </div>
    )
  }
else{
  return (
    <>
      <ToastContainer />
      <div className="table_design_area d-none d-lg-block">
        <div className='page_heading_text'>
          <h1>Dashboard</h1>
        </div>
        <div className="dashboard_screen text-center">
          <img src={DashboardImg} className="img-fluid" alt="" />
        </div>
      </div>

    </>
  );
}


}

export default Dashboard;
