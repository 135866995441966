import React from "react";
import { useLocation } from "react-router-dom";
import Loader from "../assets/images/loading-54-unscreen.gif";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RedAlert from '../assets/images/red_alert.svg'
// import GreenAlert from '../assets/images/green_alert.svg'
import axios from "axios";
import configData from "../config/config.json";
import Cookies from 'js-cookie';
import { jwtDecode } from "jwt-decode";


const Login = (props) => {
const search = useLocation().search;
const email = new URLSearchParams(search).get("email");

// const notifysuccess = (msg) => toast.success(<>
//     <img src={GreenAlert} alt="" className="img-fluid me-2" />
//     {msg}
//   </>, {
//     autoClose: 2000, position: "top-center"
//   });
  const notifyfailure = (msg) => toast.error(<>
    <img src={RedAlert} alt="" className="img-fluid me-2" />
    {msg}
  </>, { autoClose: 1500, position: "top-center" });
  const notifycatch = () =>
    toast.error(
      <>
        <img src={RedAlert} alt="" className="img-fluid me-2" />
        {"Something went wrong"}
      </>

      , { autoClose: 1500, position: "top-center" });


      // const isJwtToken = (token) => {
      //   try {
      //     const decodedToken = jwtDecode(token);

      //     return true;
      //   } catch (error) {
         
      //     return false;
      //   }
      // };   

  React.useEffect(() => {

    // if(isJwtToken(token)){
      // email: jwtDecode(token)?.email,

    let payload = {
        email: email,
      };

    axios
    .post(configData.SERVER_URL + "pubLoginEmail", payload)
    .then((response) => {
      if (response.data.status === 200) {

        // notifysuccess(response.data.message);

        setTimeout(() => {

          if (response.data.data) {
            let step = jwtDecode(response.data.data).step;
            let status = jwtDecode(response.data.data).status;
            if (step === 1 && status==="active") {

              Cookies.set(
                "token_v1",
                response.data.data,
                { expires: 1 }
              );
              window.location.replace("/account")
            }
            else {

              Cookies.set(
                "token_v1",
                response.data.data,
                { expires: 1 }
              );
              window.location.replace("/onboarding")
            }

          }

        }, 1000);
      } else {

        notifyfailure(response.data.message);
  
      }
    })
    .catch((error) => {
      notifycatch();

    });

// }
// else{
//     notifyfailure("invalid token")
// }

    
  }, [email])

    return <>
    <ToastContainer/>
      <div className="loader_img">
        <img src={Loader} className="img-fluid" alt="" />
      </div>
    </>


};

export default Login;
