import { isExpired } from "react-jwt";
import Cookies from 'js-cookie';
import {jwtDecode} from 'jwt-decode';
import axios from "axios";
import configData from "../config/config.json";


export const TokenVerificationLoggedin = () => {
    let token= Cookies.get("token_v1")
    let status= Cookies.get("token_v1")?true:false;
    if(!status){
        window.location.replace("/login")
    }
    if(status){
    const isMyTokenExpired = isExpired(token);
    if(isMyTokenExpired){
        Cookies.remove("token_v1")
        window.location.replace("/login")
      }
    }   
  }

  export const TokenVerificationLoggedOut = () => {
      let status= Cookies.get("token_v1")?true:false;      
       if (status) {

        window.location.replace("/account")
      
      } 
  }


  export const BlockDashboard = () => {
    let status= Cookies.get("token_v1")?true:false;      
    if (status) {
    let token= Cookies.get("token_v1")

      axios
        .get(configData.SERVER_URL + `getStep`, {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": token,
          },
        })
        .then((response) => {
          if (response.data.status === 200) {
         
            if(response.data.data.updatedToken){
              Cookies.set("token_v1", response.data.data.token, { expires: 1 });
              }
         
            let step = jwtDecode(response.data.data.token).step;
            let status = jwtDecode(response.data.data.token).status;

            if(!(step===1 && status==="active")){
              window.location.replace("/onboarding");
            }

      
          } else {
            let step = jwtDecode(token).step;
            let status = jwtDecode(token).status;

            
    if(!(step===1 && status==="active")){
      window.location.replace("/onboarding")
    }
          }
        })
        .catch((error) => {
          let step = jwtDecode(token).step;
          let status = jwtDecode(token).status;

          
    if(!(step===1 && status==="active")){
      window.location.replace("/onboarding")
    }      
        });

    }
    }

    // export const BlockOnboarding = () => {

    //   let status= Cookies.get("token_v1")?true:false;
    
    //   if(status){
    
    //   let token= Cookies.get("token_v1")

    //   let step=jwtDecode(token).step
    //   let status=jwtDecode(token).status

    //   if(step===1 && status==="active"){
    //     window.location.replace("/dashboard")
    //   }

    //   }
    
    // }






    


    
  
  