import './App.css';
import { Routes, Route } from "react-router-loading";
import 'bootstrap/dist/css/bootstrap.min.css';
// import "../src/styles/aayush.css";
import '../src/styles/signup.css';
import '../src/styles/selfmade.css';
import '../src/styles/main.css';
import '../src/styles/header.css';
import '../src/styles/UserDetailsPage.css';
import '../src/styles/alltables.css';
import '../src/styles/accounts.css';
import '../src/styles/modalpopup.css';
import '../src/styles/accordion.css';
import '../src/styles/dashboard.css';
import '../src/styles/responsive.css';

import {
  // before login pages
  RegisterPage,
  LoginPage,
  // EmailResendPage,
  ForgotPasswordPage,
  ResetPasswordPage,

  // after login pages
  OnboardingPage,
  DashbaordPage,
  ListCampaignPage,
  ReportingPage,
  AccountPage,
  ProfilePage,

} from "./pages";


import {
  Header, NoPageComponent,Outsider
} from "./components";


import UserProfileProperty from './components/UserProfileProperty';
import TabsComponent from './components/TabsComponent';
import NewAccordionPage from './components/NewAccordionPage';
// import TestCompon from './components/TestCompon';


function App() {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={<LoginPage />} />

        <Route path="/vscrwtdcvhydy" element={<Outsider/>} />
        <Route path="/register" element={< RegisterPage />} />
        {/* <Route path="/resendEmail" element={< EmailResendPage />} /> */}
        <Route path="/login" element={< LoginPage />} />
        <Route path="/forgotPassword" element={< ForgotPasswordPage />} />
        <Route path="/resetPassword" element={< ResetPasswordPage />} />



        <Route path="/onboarding" element={<OnboardingPage />} />
        <Route path="/dashboard" element={<DashbaordPage />} />
        {/* <Route path="/reporting" element={<ReportingPage />} /> */}
        {/* <Route path="/listCampaign" element={<ListCampaignPage />} /> */}
        <Route path="/account" element={<AccountPage />} />
        <Route path="/profile" element={<ProfilePage />} />

        {/* add new by tej */}
        {/* <Route path="/test-comp" element={<TestCompon />} /> */}

        {/* <Route path="/tabs" element={<TabsComponent />} /> */}
        {/* <Route path="/user-profile-property" element={<UserProfileProperty />} /> */}

        <Route path="*" element={<NoPageComponent />} />
      </Routes>


    </div>

  );
}

export default App;
