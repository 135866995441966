import ResetPassword from "../components/ResetPassword";

function ResetPasswordPage() {
  return (
    <>
      <ResetPassword />
    </>
  );
}

export default ResetPasswordPage;